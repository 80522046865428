<template>
  <div class="junctions-nav__list unprint">
    <div class="item" v-for="(junction, i) in junctions" :key="`junction-${i}`">
      <button class="item__delete" @click="deleteJunction(i)" v-if="isShowDelete"></button>
      <router-link class="link" :to="`/${$i18n.locale}/${placement}/${sectorIndex}/junction/${i}`">
        <img
          src="@/assets/img/icons/junctions-nav--custom.svg"
          alt=""
          class="link__icon"
          v-if="junction.isCustom"
        />
        <img src="@/assets/img/icons/junctions-nav--tn.svg" alt="" class="link__icon" v-else />
        <div
          class="link__text"
          :content="getJunctionName(junction.isCustom, junction.customJunctionName, i)"
          v-tippy="{ placement: 'right', maxWidth: 200 }"
        >
          {{ getFormattedStr(getJunctionName(junction.isCustom, junction.customJunctionName, i)) }}
        </div>
        <div class="link__footer">
          <div class="link__footer-count">
            {{ junction.count }}
            <i18n :path="junction.type === 'linear' ? 'message.units.mp' : 'message.units.pc'" />
          </div>
        </div>
      </router-link>
    </div>
    <button class="result" @click="goToResult">
      <svg
        width="22"
        height="26"
        viewBox="0 0 22 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.87354 3C2.87124 3.00884 2.86957 3.0199 2.86957 3.03334V10.7333L19.1304 10.7333V3.03334C19.1304 3.0199 19.1288 3.00884 19.1265 3H2.87354ZM2.86957 13.7334V16.8666H6.14907V13.7334H2.86957ZM9.01863 13.7334V16.8666H12.2983V13.7334H9.01863ZM15.1678 13.7333V23L19.1264 22.9999C19.1287 22.9911 19.1304 22.98 19.1304 22.9666L19.1304 13.7334L15.1678 13.7333ZM15.1678 13.7333L19.1304 13.7334L15.1678 13.7333ZM12.2983 23V19.8666H9.01863V23H12.2983ZM6.14907 23V19.8666H2.86957V22.9667C2.86957 22.9801 2.87124 22.9912 2.87354 23H6.14907ZM0 3.03334C0 1.46332 1.16334 0 2.80124 0H19.1988C20.8367 0 22 1.46332 22 3.03334L21.9999 22.9666C21.9999 24.5366 20.8366 25.9999 19.1987 25.9999L2.80124 26C1.16334 26 0 24.5367 0 22.9667V3.03334Z"
          fill="#EA454E"
        ></path>
      </svg>
      <i18n
        :path="
          this.placement === 'system'
            ? 'message.junctions.calculate'
            : 'message.details.junction.backToResult'
        "
        class="result__text"
      >
        <template #break>
          <br />
        </template>
      </i18n>
    </button>
    <leave-step-modal :is-modal-open="isLeaveStepModalOpen" @closeModal="closeLeaveStepModal" />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { findJunctionOriginalName, sliceStr } from '@/utils'
import { isAllJunctionsValid } from '@/middlewares'
import LeaveStepModal from '@/components/elements/Modals/LeaveStepModal'

export default {
  components: { LeaveStepModal },
  props: {
    junctions: Array,
    placement: String,
    isShowDelete: {
      type: Boolean
    }
  },
  data: () => ({
    isLeaveStepModalOpen: false
  }),
  methods: {
    ...mapMutations({
      DELETE_JUNCTION: 'DELETE_JUNCTION'
    }),
    deleteJunction(junctionIndex) {
      this.DELETE_JUNCTION({
        sectorIndex: this.sectorIndex,
        junctionIndex
      })
    },
    getFormattedStr(str) {
      return sliceStr(str)
    },
    goToResult() {
      if (this.isAllJunctionsValid) {
        this.$router.push(`/${this.$i18n.locale}/result`)
      } else {
        this.isLeaveStepModalOpen = true
      }
    },
    closeLeaveStepModal() {
      this.isLeaveStepModalOpen = false
    },
    getJunctionName(isCustom, customName, index) {
      if (isCustom) {
        return customName
      } else {
        return findJunctionOriginalName(index, this.currentSector.junctions, this.allJunctions)
        // if (typeof groupId !== 'undefined') {
        //   return this.originalGroupJunction(groupId, id).name
        // } else {
        //   return this.allJunctions.find(p => p.id === id).name
        // }
      }
    },
    originalSingleJunction(id) {
      return this.allJunctions.find(p => p.id === id)
    },
    originalGroupJunction(groupId, id) {
      const group = this.allJunctions.find(p => p.id === groupId).items
      return group.find(p => p.id === id)
    }
  },
  computed: {
    ...mapState({
      allJunctions: state => state.junctions,
      sectors: state => state.sectors
    }),
    sectorIndex() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    currentSector() {
      return this.sectors[this.sectorIndex]
    },
    isAllJunctionsValid() {
      return isAllJunctionsValid(this.sectors)
    }
  }
}
</script>
<style lang="sass">
.tippy-popper
  font-weight: 500
  font-size: rem(12)
</style>
<style scoped lang="sass">
.junctions-nav
  &__list
    display: grid
    +media((grid-template-columns: (320: 1fr, 480: repeat(2, 1fr), 576: repeat(3, 1fr), 650: repeat(4, 1fr), 800: repeat(5, 1fr), 960: repeat(6, 1fr))))
    padding-bottom: rem(24)
    margin: auto
    +media((width: (320: 92%, 960: 100%)))
    .item
      position: relative
      &__delete
        position: absolute
        right: 0
        top: 0
        @extend .clear-btn
        cursor: pointer
        +media((height: (320: 100%, 480: rem(30))))
        width: rem(30)
        background-image: url("~@/assets/img/nav/nav-delete.svg")
        background-repeat: no-repeat
        background-position: center center
        @media (any-hover: hover)
          &:hover
            background-image: url("~@/assets/img/nav/nav-delete--hover.svg")
    .link
      height: 100%
      background: #FFFFFF
      cursor: pointer
      transition: .5s
      text-decoration: none
      display: flex
      flex-direction: column
      align-items: center
      +media((display: (320: grid, 576: flex)))
      grid-template-columns: 11fr  1fr 32fr rem(30)
      grid-template-rows: 1fr 1fr
      +media((padding: (320: rem(15) 0, 576: rem(16) rem(12) rem(8))))
      border: rem(1) solid #ECECEC
      box-sizing: border-box
      &__icon
        grid-row: 1 / 3
      &__text
        width: 100%
        @extend .fs-12
        +media((padding: (320: 0, 576: rem(12) 0 rem(8))))
        grid-column-start: 3
      &__footer
        display: flex
        align-items: center
        +media((justify-content: (320: flex-start, 576: space-between)))
        width: 100%
        @extend .fs-12
        font-weight: bold
        color: $dark-grey
        flex-direction: row
        +media((margin-top: (320: 0, 576: auto)))
        grid-column-start: 3
        grid-row-start: 2
        // &-count
          margin-left: rem(32)
    .result
      @extend .clear-btn
      @extend .fs-12
      display: flex
      +media((flex-direction: (320: row, 576: column)))
      justify-content: center
      align-items: center
      +media((min-height: (320: rem(56), 576: rem(144))))
      border-radius: rem(4)
      +media((margin: (320: 0, 576: rem(8))))
      background: rgba(255, 255, 255, 0.5)
      svg
        path
          transition: .5s
      &__text
        font-family: $main-font
        +media((text-align: (320: left, 576: center)))
        +media((padding: (320: 0 0 0 rem(20), 576: rem(9) 0 0 0)))
        br
          +media((display: (320: none, 576: inline-block)))
      &:hover
        background: $green
        color: #fff
        svg
          path
            fill: #fff
</style>
