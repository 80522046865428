<template>
  <g class="fastener-group">
    <path
      :d="getFastenerPath(i, n, value, realIndex).fastenerBody"
      stroke-width="2"
      class="fastener-body"
      :content="`№ ${currentNumber} | <strong>${fastenerName}</strong> | ${fastenerHeight}`"
      v-tippy="{
        followCursor: true,
        animation: 'shift-toward',
        delay: 100,
        arrow: !isInMobileModal,
        placement: 'right'
      }"
      stroke="rgba(51, 51, 51, 0.3)"
      fill="#fff"
    ></path>
    <ellipse
      :cx="getFastenerPath(i, n, value, realIndex).ellipse.x"
      :cy="getFastenerPath(i, n, value, realIndex).ellipse.y"
      rx="12"
      ry="6"
      class="fastener-hat"
      stroke-width="2"
      stroke="rgba(51, 51, 51, 0.3)"
      fill="#fff"
    />
    <ellipse
      :cx="getFastenerPath(i, n, value, realIndex).ellipseMini.x"
      :cy="getFastenerPath(i, n, value, realIndex).ellipseMini.y"
      class="fastener-hat"
      rx="6"
      ry="3"
      fill="rgba(51, 51, 51, 0.3)"
    />
  </g>
</template>

<script>
import { getFastenerPath } from '@/utils/drawing'
import { getFastenerInfo, getItemNumber } from '@/utils/customFunctions'
import { cloneDeep } from 'lodash'
export default {
  props: {
    i: Number,
    n: Number,
    value: Number,
    realIndex: Number,
    fastenerName: String,
    fastenerHeight: Number,
    currentNumber: Number,
    coefficient: Number,
    filteredLayers: Array,
    parentLayers: Array,
    fastenersOnScheme: Array,
    defaultBaseId: Number,
    isInMobileModal: {
      type: Boolean,
      default: false
    },
    isInFullScreen: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getFastenerPath(layerIndex, itemIndex, height, layerRealIndex) {
      const fastenerHeight = this.getFastenerParams(layerIndex, itemIndex).fastenerHeight
      // console.log(fastenerHeight)
      const collectedLayers = this.getFastenerParams(layerIndex, itemIndex).collectedLayers
      const layerNumber = this.getCurrentNumber(layerRealIndex, itemIndex)
      return getFastenerPath(
        this.filteredLayers,
        layerIndex,
        itemIndex,
        height,
        this.coefficient,
        fastenerHeight,
        collectedLayers,
        this.fastenersOnScheme,
        layerNumber,
        this.isInFullScreen
      )
    },
    getFastenerParams(totalIndex, localIndex) {
      const arr = cloneDeep(this.filteredLayers)
      return getFastenerInfo(totalIndex, localIndex, arr, this.defaultBaseId, true)
    },
    getCurrentNumber(parentIteration, childIteration) {
      return getItemNumber(parentIteration, childIteration, this.parentLayers)
    }
  }
}
</script>

<style lang="sass">
.tippy-popper
  font-weight: 500
</style>
