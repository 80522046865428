<template>
  <div class="tables-layout">
    <div class="details-title">
      <span class="details-title__text">{{
        $t(`message.details.${placement}.usedMaterials.title`)
      }}</span>
      <button
        @click="isShowTables = !isShowTables"
        :class="{ 'table-toggler': true, active: isShowTables }"
      >
        <span class="bar"></span>
      </button>
    </div>
    <slot v-if="isShowTables"></slot>
  </div>
</template>

<script>
export default {
  props: {
    placement: {
      type: String,
      default: 'system'
    }
  },
  data: () => ({
    isShowTables: false
  })
}
</script>

<style scoped lang="sass">
.tables-layout
  .details-title
    display: grid
    grid-template-columns: 1fr rem(40)
    &__text
      display: flex
      flex-direction: row
      align-items: center
      padding-right: rem(15)
.table-toggler
  @extend .clear-btn
  outline: none
  transition: .5s
  height: rem(40)
  width: rem(40)
  background: rgba(255, 255, 255, 0.5)
  cursor: pointer
  border-radius: 50%
  position: relative
  &.active
    .bar
      &:before
        opacity: 0
  &:hover
    background: #fff
  .bar
    width: rem(10)
    height: rem(2)
    background: $red
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    &:before
      content: ""
      top: 0
      left: 0
      width: 100%
      height: 100%
      background: $red
      transition: .5s
      transform: rotate(90deg)
      position: absolute
</style>
