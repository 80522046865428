<template>
  <div class="used-materials__table--details used-materials__table--details--junctions">
    <div class="table-head">
      <div class="titles">
        <div class="titles__left">
          <div class="title">
            {{ $t(`message.details.system.usedMaterials.table.junctionMaterials`) }}
          </div>
        </div>
        <div class="titles__right">
          <div class="title">
            {{ $t(`message.details.system.usedMaterials.table.count`) }} <br />
            <span class="description">
              {{ $t(`message.details.system.usedMaterials.table.computation`) }}
            </span>
          </div>
          <div class="title">
            {{ $t(`message.details.system.usedMaterials.table.count`) }} <br />
            <span class="description">
              {{ $t(`message.details.system.usedMaterials.table.package`) }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="table-body" v-for="(item, i) in computation" :key="`junction-item-${i}`">
      <div class="table-body__title">
        <div class="text">
          {{ item.name }}
        </div>
        <!-- <div class="text">
          {{ $t(`message.details.system.usedMaterials.table.junctionType`) }}
          {{ getJunctionName(2) }}
        </div> -->
      </div>
      <div
        class="table-body__item"
        v-for="(material, n) in item.materials"
        :key="`junction-item-${i}-material-${n}`"
      >
        <div class="table-body__item-left">
          <div class="content">{{ material.name }}</div>
        </div>
        <div class="table-body__item-right">
          <div class="content">{{ material.count }} {{ material.units }}</div>
          <!-- $t(`message.units.${material.units}`) -->
          <div class="content">{{ material.countPackage }} {{ material.units }}</div>
          <!-- $t(`message.units.${material.units}`) -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapState } from 'vuex'
// import { findJunctionOriginalName } from '@/utils'

export default {
  props: {
    computation: {
      type: Array,
      default: () => [],
      required: true
    },
    sectorJunctions: {
      type: Array,
      default: () => [],
      required: true
    }
  }
  // methods: {
  //   // getJunctionName(index) {
  //   //   return findJunctionOriginalName(index, this.sectorJunctions, this.allJunctions)
  //   // }
  // },
  // computed: {
  //   // ...mapState({
  //   //   allJunctions: state => state.junctions
  //   // })
  // }
}
</script>

<style lang="sass" scoped>
@import "~@/assets/sass/details-big-table.sass"
</style>
