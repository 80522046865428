<template>
  <div class="used-materials">
    <div class="used-materials__table--details">
      <div class="table-head">
        <div class="titles">
          <div class="titles__left">
            <div class="title">
              {{ $t(`message.details.${placement}.usedMaterials.table.materials`) }}
            </div>
          </div>
          <div class="titles__right">
            <div class="title">
              {{ $t(`message.details.system.usedMaterials.table.count`) }} <br />
              <span class="description">
                {{ $t(`message.details.system.usedMaterials.table.computation`) }}
              </span>
            </div>
            <div class="title">
              {{ $t(`message.details.system.usedMaterials.table.count`) }} <br />
              <span class="description">
                {{ $t(`message.details.system.usedMaterials.table.package`) }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="table-body" v-for="(item, i) in filteredComputation" :key="`system-item-${i}`">
        <div class="table-body__title">
          <div class="text">{{ item.name }}</div>
        </div>
        <div
          class="table-body__item"
          v-for="(material, n) in item.materials"
          :key="`system-item-${i}-material-${n}`"
        >
          <div class="table-body__item-left">
            <div class="content">{{ material.name }}</div>
          </div>
          <div class="table-body__item-right">
            <div class="content">{{ material.count }} {{ material.units }}</div>
            <!-- $t(`message.units.${material.units}`) -->
            <div class="content">{{ material.countPackage }} {{ material.units }}</div>
            <!-- $t(`message.units.${material.units}`) -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    placement: {
      type: String,
      default: 'system'
    },
    computation: Array
  },
  computed: {
    filteredComputation() {
      return this.computation.filter(item => item.materials.length)
    }
  }
}
</script>

<style lang="sass" scoped>
@import "~@/assets/sass/details-big-table.sass"
</style>
